import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";
import Image from "../ImageQuerys/RandomImages";
import { viewports } from "../../style-vars";

const { Title } = Typography;

const { smOrSmaller, mdOrSmaller } = viewports;

const InlineQuote = (props) => {
  const { sectionData, style } = props;
  const { header, name, title, subtext, imgs } = sectionData;
  return (
    <section className="large-features hello" style={style}>
      {header && (
        <div style={{ marginTop: 60 }}>
          <Title
            level={2}
            className="new-line"
            style={{ textAlign: "center", margin: 20 }}
          >
            {header}
            <span className="blue_dot">.</span>
          </Title>
        </div>
      )}
      <div style={{ marginTop: 40 }}>
        <Image imageName="quote.png" className="quote-img" />
      </div>
      <p className="text-center vets-p">{subtext}</p>
      <div className="figure-head">
        <Image className="figure-img" imageName={imgs} />
        <p className="text-center figure-name">{name}</p>
        <p className="figure-title text-center">{title}</p>
      </div>
      <style jsx global>{`
        .hello {
          position: relative;
          margin-bottom: 100px;
        }
        .vets-p {
          color: #748093;
          line-height: 160%;
          font-size: 16px;
          padding: 0 20%;
        }
        .figure-img {
          width: 100px;
          display: table;
          margin: 0 auto;
          border-radius: 50%;
          border: 2px solid white;
          margin-bottom: 15px;
        }
        .figure-name {
          font-weight: 800;
          font-size: 18px;
          line-height: 130%;
          text-align: center;
          color: #090d12;
        }
        .figure-title {
          font-size: 14px;
          line-height: 130%;
          text-align: center;
          letter-spacing: -0.01em;
          color: #8a95a6;
        }
        .bg-shadow {
          position: absolute;
          width: 75%;
        }
        .quote-img {
          width: 25px !important;
          height: 15px;
          object-fit: scale-down;
          display: table;
          margin: 16px auto;
        }
        @media screen and (max-width: 1024px) {
          .hello {
            margin-top: 100px;
          }
        }
        @media ${mdOrSmaller} {
          .vets-p {
            padding: 0 10%;
          }
        }
        @media ${smOrSmaller} {
          .vets-p {
            padding: 0 16px;
          }
        }
      `}</style>
    </section>
  );
};

InlineQuote.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  style: PropTypes.objectOf(PropTypes.any),
};
InlineQuote.defaultProps = {
  sectionData: "",
  style: {},
};

export default InlineQuote;
