import React from "react";
import loadable from "@loadable/component";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import {
  vetsLPHero,
  vetsLPWhiteGlove,
  vetsLPLargeFeatures,
  vetsLPCTA,
  vetsLPTestimonials,
  customVets,
} from "../../../data/landing/vets-data";
import Hero from "../../../components/Hero/hero";
import ogImage from "../../../images/global_assets/og-image.png";
import InlineQuote from "../../../components/InlineQuote";

const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);

const VetsSpecial = loadable(() => import("../../../components/VetsSpecial"));

const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));

const whiteGloveImport = "vets-white-glove.png";

const OrderingLP = () => {
  return (
    <LpLayout phoneNumber="+13035240930" phoneText="(303) 524-0930">
      <SEO
        title="SpotOn | Veterans Day Offer | Point-of-Sale"
        description=" Military veterans, save up to $500 on a new payment terminal or point-of-sale, with built-in tools to run and grow your business and the personalized service and support you deserve."
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />

      <Hero sectionData={vetsLPHero} circleBg={false} />
      <InlineQuote sectionData={vetsLPTestimonials} />
      <WhiteGlove
        sectionData={vetsLPWhiteGlove}
        whiteGloveBg={whiteGloveImport}
        noTime
      />
      <LargeFeatures
        sectionData={vetsLPLargeFeatures}
        style={{ marginTop: "60px" }}
      />
      <VetsSpecial sectionData={customVets} />
      {/* <section style={{ marginTop: "60px" }}> */}
      {/*  <TestmonialReviews sectionData={vetsLPTestimonials} /> */}
      {/* </section> */}
      <LargeCta sectionData={vetsLPCTA} />
    </LpLayout>
  );
};

export default OrderingLP;
