/* eslint-disable import/prefer-default-export */

/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const vetsLPHero = {
  title: "Proudly supporting veteran-owned businesses",
  subtext:
    "Save up to $500 on a new payment terminal or point-of-sale, with built-in tools to run and grow your business and the personalized service and support you deserve.",
  heroImg: "vets-hero.jpg",
  imageClassName: "",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/veterans/demo",
  },
  fatTitle: "",
};

export const vetsLPWhiteGlove = {
  mainTitle:
    "You stepped up when our country needed you. Now it’s our turn to support your business",
  title: "Five star service & support",
  content:
    "Our team will work closely with you to configure your point-of-sale or payment device in as little as 48 hours and then train you and your staff to get the most from it. Once you’re up and running, we’ll continue to be there for you with local service and around-the-clock technical support, included at no extra cost.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/veterans/demo",
  },
};

export const vetsLPLargeFeatures = {
  sectionTitle: "The tech you need to run & grow your business",
  featureBlocks: [
    {
      blockTitle: "For restaurants—serve more guests & streamline operations",
      blockImg: "vets-a.png",
      blockSubTitle: "",
      blockList: [
        "Turn more tables with lightning-fast POS, handhelds, and QR ordering",
        "Increase sales with commission-free online & mobile ordering ",
        "Improve efficiency with integrated front-of-house and back-of-house tools",
        "Get smarter reporting and customer insights—available online anytime, anywhere",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/veterans/demo",
      },
    },
    {
      blockTitle: "For retail—sell better \n in-store & online",
      blockImg: "vets-b.png",
      blockList: [
        "Manage your products, services, and stock from one cloud-based catalog",
        "Easily publish to your in-store POS and ecommerce store",
        "Offer same day delivery and in-store pickup options for local customers",
        "Reach new customers with built-in tools to ship nationwide",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/veterans/demo",
      },
    },
    {
      blockTitle: "Grow your business with powerful, built-in tools",
      blockImg: "vets-c.png",
      blockList: [
        "Mailing-list builder automatically imports new customers to grow your customer base",
        "Send digital coupons, marketing emails, and Facebook campaigns",
        "Monitor your online reputation on Yelp and Google",
        "Add optional loyalty rewards to drive repeat business",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/veterans/demo",
      },
    },
  ],
};

export const vetsLPTestimonials = {
  title: "Owner of Car Buyer’s Advocate",
  name: "Bob Dittel",
  imgs: "bob-dittel.png",
  subtext:
    "“Veterans have this thing about them—they want to get the job done and they want to get it done well. When I met John from SpotOn, even before he told me that he had served in the military, I could sense his honor and integrity, and my first impression has proven to be absolutely accurate. I love the email marketing capabilities SpotOn offers me by keeping it simple. I'm not a big fluffy person. I'm pretty straightforward. I'm going to get you the deal and take care of you. SpotOn's email marketing makes it easy for me to share that message with my customers.”",
};

export const vetsLPCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/veterans/demo",
  },
};

export const customVets = {
  title: "Special offer for veteran-owned businesses",
  subtext:
    "Must show military ID, or DD214. Rebate of up to $500 applied upon activation toward hardware costs (including SpotOn Restaurant, SpotOn Retail, and SpotOn Sidekick), not to exceed list price of selected hardware. Limited to 1 device per location. Offer eligible with installment plans.",
  imgs: ["vets-1.png", "vets-2.png", "vets-3.png"],
};
